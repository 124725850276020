import { ReactNode } from 'react';

import { z } from 'zod';

import { apiClient } from './api/api';
import { AvailPermission } from './components/utils/PermissionsGuard';
import { LOCAL_KEY_AUTH_SSO_ID_TOKEN } from './contexts/AuthCtx';
import { AUTH_URL } from './env';

export function typedKeys<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof typeof obj>;
}

type WithCustomFieldReactNode<key extends string, T, RetType, Options = {}> = {
  [newKey in key]: (feObj: T, options: Options) => RetType;
};

export type WithRenderField<T, Options = {}> = WithCustomFieldReactNode<
  'renderField',
  T,
  ReactNode,
  Options
>;

export type WithValueGetter<T, Options = {}> = WithCustomFieldReactNode<
  'valueGetter',
  T,
  any,
  Options
>;

export type Primitive = number | boolean | string;

export type someKeysOfWithCustomField<
  Base,
  T,
  customField extends string,
  RetType,
  NoCustomFieldRequiredCondition = unknown,
  AdditionalTypeConditionExtend = never,
  AdditionalType = {},
  CustomFieldOptions = {}
> = {
  [key in keyof Base]?: T &
    (Base[key] extends NoCustomFieldRequiredCondition
      ? Partial<
          WithCustomFieldReactNode<
            customField,
            Base,
            RetType,
            CustomFieldOptions
          >
        >
      : WithCustomFieldReactNode<
          customField,
          Base,
          RetType,
          CustomFieldOptions
        >) &
    (Base[key] extends AdditionalTypeConditionExtend ? AdditionalType : {});
};

export type WithId<T> = T & { id: string };
export type WITH_ID_DEFAULT_TYPE = { id: '' };

export const extractNameInitials = (name: string) =>
  name
    .trim()
    .split(' ')
    .slice(0, 2)
    .filter(item => item.length > 0)
    .map(name => name[0].toUpperCase())
    .join('');

export const uppercaseFirstLetter = (words: string) =>
  words.charAt(0).toUpperCase() + words.slice(1);

export const replaceHtmlTags = (value: string, substitute: string) =>
  value.replaceAll(/<[^>]*>/gm, substitute);

const isDate = (val: any) => val instanceof Date;

export const isArray = (val: any) => Array.isArray(val);

export const isObjOrArray = (val: any) =>
  typeof val === 'object' && val !== null && !isDate(val);

const isObj = (val: any): boolean => isObjOrArray(val) && !isArray(val);

export const flattenObj = (
  obj: Record<string, any>,
  appendKey: boolean,
  keySeparator: string = '.'
) => {
  // The object which contains the
  // final result
  const result: Record<string, any> = {};

  // loop through the object
  for (const objKey in obj) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (isObj(obj[objKey])) {
      const temp = flattenObj(obj[objKey], appendKey, keySeparator);
      for (const tempKey in temp) {
        // Store temp in result
        if (appendKey)
          result[`${objKey}${keySeparator}${tempKey}`] = temp[tempKey];
        else result[tempKey] = temp[tempKey];
      }
    }
    // Else store ob[i] in result directly
    else {
      result[objKey] = obj[objKey];
    }
  }

  return result;
};

export type ButtonAction<OnClickArgs = undefined> = {
  onClick?: OnClickArgs extends undefined
    ? () => void
    : (args: OnClickArgs) => void;
  level?: 'warning' | 'error';
  type?: 'button' | 'submit';
  variant?: 'outlined' | 'contained';
  formId?: string;
  shouldAskConfirm?: boolean;
  disabled?: boolean;
  requiredPermissions?: AvailPermission[];
} & (
  | {
      icon?: ReactNode;
      label?: never;
    }
  | {
      icon?: never;
      label: string;
    }
  | {
      icon: ReactNode;
      label: string;
    }
);

export type JSONValue =
  | string
  | number
  | boolean
  | null
  | { [x: string]: JSONValue }
  | Array<JSONValue>;

export const requiredStringSchema = z.string().trim().min(1);

export const requiredRichTextSchema = z
  .string()
  .trim()
  // this line gives weird warning with eslint in vscode (conflict between eslint plugin and prettier plugin), but the pre-commit hook works fine
  .transform(value => value.replaceAll('‘', '\'').replaceAll('’', '\''))
  .refine(value => replaceHtmlTags(value ?? '', ' ').trim().length > 0);

function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((res, rej) => {
    const reader = new FileReader();
    reader.onload = () => res(reader.result as string);
    reader.onerror = () => rej(reader.error);
    reader.onabort = () => rej(new Error('Read aborted'));
    reader.readAsDataURL(blob);
  });
}

export const srcToJpg = async (
  src: string,
  fileName: string,
  mimeType: string = 'image/png',
  extension: string = 'png',
  params?: string[][]
) => {
  try {
    const p: { [key: string]: string } = {};
    params?.forEach(param => {
      if (param.length > 1) {
        p[param[0]] = param[1];
      }
    });
    const picBlob = await apiClient.get(src, {
      responseType: 'blob',
      params: p,
    });

    return new File([picBlob.data], `${fileName}.${extension}`, {
      type: mimeType,
    });
  } catch (err) {
    console.error(`ERROR WHEN FETCHING SRC JPG ${src}`, err);
    return;
  }
};

export const privateToDataUrl = (url: string, token: string) =>
  fetch(url, {
    headers: AUTH_URL
      ? {
          authorization: token ? `Bearer ${token}` : '',
          'x-id-token': localStorage.getItem(LOCAL_KEY_AUTH_SSO_ID_TOKEN) ?? '',
        }
      : {
          token: token ?? '',
        },
  })
    .then(res => {
      if (!res.ok) {
        return Promise.reject(`REQUEST ERROR, STATUS ${res.status}`);
      }

      return res.blob();
    })
    .then(blob => blobToDataURL(blob));

export const deleteUndefinedProperties = (obj: any) => {
  if (!obj) return obj;
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
  return obj;
};

export const JSONparseSafe = (str: string | null) => {
  if (!str) return undefined;
  try {
    const parsed = JSON.parse(str);
    return parsed;
  } catch (err) {
    return undefined;
  }
};

export type FieldArrayItemMng = {
  onInsertClick?: () => void;
  disabledInsert?: boolean;
  onDuplicateClick?: (item: any) => void;
  disabledDuplicate?: boolean;
  onRemoveClick: () => void;
};

export type FieldArrayPosMng = FieldArrayItemMng & {
  onMoveNextClick: () => void;
  disabledMoveNext?: boolean;
  onMovePrevClick: () => void;
  disabledMovePrev?: boolean;
};

export const GENERIC_URL_REGEX =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gm;

const URL_WITHOUT_PROT_REGEX =
  /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/gm;

export const regexTest = (str: string, regex: RegExp) =>
  (str.match(regex)?.length ?? 0) > 0;

export const completeUrl = (url: string) => {
  let completeUrl = url.trim();

  if (regexTest(completeUrl, URL_WITHOUT_PROT_REGEX)) {
    const doesIncludeWWW = url.includes('www.');
    completeUrl = `http://${doesIncludeWWW ? '' : 'www.'}${url}`;
  }
  return completeUrl;
};

export const triggerUrlDownload = async (
  fileSrc: string,
  fileName: string,
  token: string,
  privateUrl: boolean = true
) => {
  const privateDataUrl = privateUrl
    ? await privateToDataUrl(fileSrc!, token ?? '')
    : fileSrc;

  const tempATag = document.createElement('a');
  tempATag.href = privateDataUrl;
  tempATag.setAttribute('download', fileName);
  tempATag.click();
  tempATag.remove();
};

export const triggerTextDownload = (text: string, fileName: string) => {
  const elm = document.createElement('a');
  elm.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
  );
  elm.setAttribute('download', fileName);

  elm.style.display = 'none';
  document.body.appendChild(elm);

  elm.click();

  document.body.removeChild(elm);
};

export const triggerLocalCsv = (
  headers: string[],
  rows: string[][],
  fileName: string
) =>
  triggerTextDownload(
    [headers.join(','), ...rows.map(row => row.join(','))].join('\n'),
    fileName
  );

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export const randomTimeId = (prefix: string = '') =>
  `${prefix}${getRandomInt(1000000000, 9999999999)}${Date.now()}`;

export function replaceMultiple(
  str: string,
  mapObj: { [toReplace: string]: string }
) {
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()];
  });
}

export const getFileText = <T extends string | ArrayBuffer = string>(
  file: File
) =>
  new Promise<T>((res, rej) => {
    const reader = new FileReader();
    reader.onload = function () {
      if (reader.result) res(reader.result as T);
      else rej('Read result was null');
    };
    reader.onerror = () => rej(reader.error);
    reader.onabort = () => rej(new Error('Read aborted'));
    reader.readAsText(file);
  });

export const onlyUniqueFilter = (value: any, index: any, array: any) =>
  array.indexOf(value) === index;

export const onlyUniqueFilterOnProp =
  (prop: string) => (value: any, index: any, array: any[]) =>
    array.findIndex((a: any) => a[prop] === value[prop]) === index;

export const tokenIsBearer = (token: string): boolean => {
  var base64Url = token.split('.')[1];
  if (base64Url) {
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );

    const tokenJson = JSON.parse(jsonPayload);

    // Since we are using id_token and not token, the type is not available,
    // and at the moment we are using every JWT token as Bearer
    return !!tokenJson.iss;
  }
  return false;
};

export const extensionFromMime: { [key: string]: string } = {
  'text/html': '.html',
  'text/css ': '.css',
  'text/xml ': '.xml',
  'image/gif': '.gif',
  'image/jpeg ': '.jpeg',
  'application/x-javascript ': '.js',
  'application/atom+xml ': '.atom',
  'application/rss+xml': '.rss',

  'text/mathml ': '.mml',
  'text/plain': '.txt',
  'text/vnd.sun.j2me.app-descriptor': '.jad',
  'text/vnd.wap.wml': '.wml',
  'text/x-component': '.htc',

  'image/png ': '.png',
  'image/tiff': '.tif',
  'image/vnd.wap.wbmp': '.wbmp',
  'image/x-icon': '.ico',
  'image/x-jng ': '.jng',
  'image/x-ms-bmp': '.bmp',
  'image/svg+xml ': '.svg',
  'image/webp': '.webp',

  'application/java-archive': '.jar',
  'application/mac-binhex40': '.hqx',
  'application/msword': '.doc',
  'application/pdf ': '.pdf',
  'application/postscript': '.ps',
  'application/rtf ': '.rtf',
  'application/vnd.ms-excel': '.xls',
  'application/vnd.ms-powerpoint ': '.ppt',
  'application/vnd.wap.wmlc': '.wmlc',
  'application/vnd.google-earth.kml+xml': '.kml',
  'application/vnd.google-earth.kmz': '.kmz',
  'application/x-7z-compressed ': '.7z',
  'application/x-cocoa ': '.cco',
  'application/x-java-archive-diff ': '.jardiff',
  'application/x-java-jnlp-file': '.jnlp',
  'application/x-makeself': '.run',
  'application/x-perl': '.pl',
  'application/x-pilot ': '.prc',
  'application/x-rar-compressed': '.rar',
  'application/x-redhat-package-manager': '.rpm',
  'application/x-sea ': '.sea',
  'application/x-shockwave-flash ': '.swf',
  'application/x-stuffit ': '.sit',
  'application/x-tcl ': '.tcl',
  'application/x-x509-ca-cert': '.der ',
  'application/x-xpinstall ': '.xpi',
  'application/xhtml+xml ': '.xhtml',
  'application/zip ': '.zip',

  'audio/midi': '.mid',
  'audio/mpeg': '.mp3',
  'audio/ogg ': '.ogg',
  'audio/x-realaudio ': '.ra',

  'video/3gpp': '.3gpp',
  'video/mpeg': '.mpeg',
  'video/quicktime ': '.mov',
  'video/x-flv ': '.flv',
  'video/x-mng ': '.mng',
  'video/x-ms-asf': '.asx',
  'video/x-ms-wmv': '.wmv',
  'video/x-msvideo ': '.avi',
  'video/mp4 ': '.mp4',
};
